<template>
  <div>
    <div v-if="data.isLinked">
      <LocationWidgetContent :data="data" :isExpandedView="isExpandedView"></LocationWidgetContent>
    </div>
    <div v-else>
      <b-row v-if="!isExpandedView">
        <b-col cols="12" id="unlinked-widget-txt" v-html="data.unlinkedWidgetTxt"></b-col>
        <b-col cols="12" class="mt-3">
          <a v-if="data.authenticationType == 'external-link-account'" href="javascript:void(0)" class="theme-font-medium" @click="connectExternalLinkAccount"
            >{{ translateLanguage('Lnk_Integration_Widget_Link_account_now') }} <ChevronRightBlue class="chevron-icon ml-1"
          /></a>
          <a v-else href="javascript:void(0)" class="theme-font-medium" @click="$emit(`showExpandedView`)"
            >{{ translateLanguage('Lnk_Integration_Widget_Link_account_now') }} <ChevronRightBlue class="chevron-icon ml-1"
          /></a>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12" id="link-account-txt" v-html="data.linkAccountTxt"></b-col>
        <b-col cols="12" class="my-3" v-if="data.authenticationType == 'external-link-account'">
          <a href="javascript:void(0)" class="theme-font-medium" @click="connectExternalLinkAccount"
            >{{ translateLanguage('Lnk_Integration_Widget_Link_account_now') }} <ChevronRightBlue class="chevron-icon ml-1"
          /></a>
        </b-col>
        <b-col cols="12" class="my-3" v-else>
          <b-form
            v-if="data.fields.length"
            id="link-account-form"
            ref="linkAccountForm"
            autocomplete="off"
            @submit.prevent="connectLinkAccount"
            @reset="resetLinkAccountForm"
            novalidate
          >
            <b-form-row v-for="(field, fIndex) in data.fields" :key="fIndex" class="link-accounts-fields">
              <b-col cols="11">
                <b-form-group>
                  <div class="floating-input-field">
                    <datepicker
                      v-if="field.fieldType == 'Date'"
                      :id="`${field.name}-${fIndex}`"
                      :bootstrapStyling="true"
                      :typeable="true"
                      :wrapperClass="{
                        'is-value-exist': field.fieldValue,
                      }"
                      v-model="field.fieldValue"
                      format="MM-dd-yyyy"
                    ></datepicker>
                    <input
                      v-else-if="field.fieldType == 'Amount'"
                      :type="field.fieldType"
                      :id="`${field.name}-${fIndex}`"
                      v-model="field.fieldValue"
                      :placeholder="field.name"
                      v-amount
                      required
                      class="form-control"
                    />
                    <input
                      v-else
                      :type="field.fieldType.toLowerCase()"
                      :id="`${field.name}-${fIndex}`"
                      v-model="field.fieldValue"
                      :placeholder="field.name"
                      required
                      class="form-control"
                    />
                    <label :for="`${field.name}-${fIndex}`">{{ field.name }}</label>
                    <b-form-invalid-feedback class="d-block" v-if="!field.fieldValue && formSubmitted">{{ field.name }} required.</b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </b-col>
              <b-col v-if="field.helperTxt" cols="1" class="mt-2 pt-1 pl-2">
                <InfoButtonTogglePopover :description="field.helperTxt" />
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="4" md="4" lg="3">
                <b-button
                  type="submit"
                  block
                  variant="primary"
                  class="btn-theme-height btn-black"
                  :style="{
                    backgroundColor: buttonColor,
                    border: `1px solid ${buttonColor}`,
                  }"
                  :disabled="saveLoadingIcon"
                  >{{ translateLanguage('Btn_Integration_Widget_Link_account')
                  }}<b-spinner v-if="saveLoadingIcon" label="Spinning" small class="ml-2"></b-spinner
                ></b-button>
              </b-col>
              <b-col cols="4" md="4" lg="3">
                <b-button type="reset" :disabled="saveLoadingIcon" class="btn-theme-height btn-white" block variant="primary">{{
                  translateLanguage('Btn_Integration_Widget_Rest_form')
                }}</b-button>
              </b-col>
            </b-form-row>
          </b-form>
          <p v-else class="theme-font-bold">{{ translateLanguage('Txt_Integration_Widget_Link_account_not_available') }}</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { DISPLAY_MESSAGES } from '../../../utilities/constants'
import ChevronRightBlue from '../../../assets/svg/chevron-right-blue.svg'
import Datepicker from 'vuejs-datepicker'
import moment from 'moment'
import InfoButtonTogglePopover from '../../common/InfoButtonTogglePopover.vue'
import { generateContactUsMessage } from '../../../utilities/utilities'
export default {
  name: 'IntegrationWidgetContent',
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      saveLoadingIcon: false,
      formSubmitted: false,
    }
  },
  props: {
    widgetId: {
      type: [Number, String],
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    isExpandedView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ChevronRightBlue,
    InfoButtonTogglePopover,
    Datepicker,
    LocationWidgetContent: () => import('../location-widget/LocationWidgetContent.vue'),
  },
  computed: {
    ...mapState({
      contactUsUrl: (state) => state.shared.contactUsUrl,
      buttonColor: (state) => state.shared.buttonColor,
    }),
  },
  mounted() {
    this.resetLinkAccountForm()
  },
  methods: {
    resetLinkAccountForm() {
      this.formSubmitted = false
      if (this.$refs.linkAccountForm) {
        this.$refs.linkAccountForm.reset()
        this.data.fields.forEach((field) => {
          field.fieldValue = null
        })
      }
    },
    connectExternalLinkAccount() {
      this.$store
        .dispatch('dashboard/connectExternalLinkAccount', this.widgetId)
        .then((res) => {
          if (res && res.data) {
            window.location.href = res.data
          } else {
            this.$store.commit('common/setCustomToastData', {
              message: `${this.translateLanguage(DISPLAY_MESSAGES.LINK_ACCOUNT_ERROR)} ${generateContactUsMessage(true, this.tenantSettings.contactUsUrl)}`,
              key: false,
              type: 'danger',
            })
          }
        })
        .catch((error) => {
          this.$store.commit('common/setCustomToastData', {
            message: `${this.translateLanguage(error.message)} ${generateContactUsMessage(true, this.tenantSettings.contactUsUrl)}`,
            key: false,
            type: 'danger',
          })
        })
    },
    connectLinkAccount() {
      this.formSubmitted = true
      const isError = this.data.fields.some((field) => !field.fieldValue)
      if (!isError) {
        this.saveLoadingIcon = true
        this.data.fields.forEach((field) => {
          if (field.fieldType === 'Date') {
            field.fieldValue = moment(field.fieldValue).format('MM-DD-YYYY')
          }
        })
        const postObj = {
          userId: 0,
          widgetId: this.widgetId,
          fields: this.data.fields,
        }
        this.$store
          .dispatch('dashboard/connectLinkAccount', postObj)
          .then((res) => {
            this.saveLoadingIcon = false
            this.formSubmitted = false
            if (res && res.data && res.data.hasOwnProperty('errorMessage')) {
              this.$store.commit('common/setCustomToastData', {
                message: res.data.errorMessage,
                key: false,
                type: 'danger',
              })
            } else if (res && res.data && res.data.length) {
              this.$store.commit('dashboard/setLinkAccountWidgetId', res.data)
              setTimeout(() => {
                this.$store.commit('dashboard/setLinkAccountWidgetId', null)
              }, 2000)
              this.$store.commit('common/setCustomToastData', {
                message: false,
                key: 'LINK_ACCOUNT_SUCCESS',
                type: 'success',
              })
            } else {
              this.$store.commit('common/setCustomToastData', {
                message: `${this.translateLanguage(DISPLAY_MESSAGES.LINK_ACCOUNT_ERROR)} ${generateContactUsMessage(true, this.tenantSettings.contactUsUrl)}`,
                key: false,
                type: 'danger',
              })
            }
          })
          .catch((error) => {
            this.saveLoadingIcon = false
            this.formSubmitted = false
            this.$store.commit('common/setCustomToastData', {
              message: `${this.translateLanguage(error.message)} ${generateContactUsMessage(true, this.tenantSettings.contactUsUrl)}`,
              key: false,
              type: 'danger',
            })
          })
      }
    },
  },
}
</script>
